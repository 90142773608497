import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderComponent } from "../../Common/LoaderComponent";

const Admin = () => {
  return (
    <div>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Dashboard</h5>
          <form id="searchBar" className="search-bar" action="index.html">
            <div className="form-control-wrapper">
              <input
                type="search"
                className="form-control bd-0"
                placeholder="Search..."
              />
            </div>
            {/* form-control-wrapper */}
            <button id="searchBtn" className="btn btn-orange">
              <i className="fa fa-search" />
            </button>
          </form>
          {/* search-bar */}
        </div>
        {/* am-pagetitle */}
        <div className="am-pagebody">
          <div className="row row-sm">
            <div className="col-lg-4">
              <div className="card">
                <div id="rs1" className="wd-100p ht-200" />
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">
                        Today's Earnings
                      </h6>
                      <p className="tx-12">November 21, 2017</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0" />
                    </a>
                  </div>
                  {/* d-flex */}
                  <h2 className="mg-b-5 tx-inverse tx-lato">$12,212</h2>
                  <p className="tx-12 mg-b-0">Earnings before taxes.</p>
                </div>
              </div>
              {/* card */}
            </div>
            {/* col-4 */}
            <div className="col-lg-4 mg-t-15 mg-sm-t-20 mg-lg-t-0">
              <div className="card">
                <div id="rs2" className="wd-100p ht-200" />
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">
                        This Week's Earnings
                      </h6>
                      <p className="tx-12">November 20 - 27, 2017</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0" />
                    </a>
                  </div>
                  {/* d-flex */}
                  <h2 className="mg-b-5 tx-inverse tx-lato">$28,746</h2>
                  <p className="tx-12 mg-b-0">Earnings before taxes.</p>
                </div>
              </div>
              {/* card */}
            </div>
            {/* col-4 */}
            <div className="col-lg-4 mg-t-15 mg-sm-t-20 mg-lg-t-0">
              <div className="card">
                <div id="rs3" className="wd-100p ht-200" />
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">
                        This Month's Earnings
                      </h6>
                      <p className="tx-12">November 1 - 30, 2017</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0" />
                    </a>
                  </div>
                  {/* d-flex */}
                  <h2 className="mg-b-5 tx-inverse tx-lato">$72,118</h2>
                  <p className="tx-12 mg-b-0">Earnings before taxes.</p>
                </div>
              </div>
              {/* card */}
            </div>
            {/* col-4 */}
          </div>
          {/* row */}
          <div className="row row-sm mg-t-15 mg-sm-t-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Bar Chart</h6>
                <p className="mg-b-20 mg-sm-b-30">
                  A bar chart or bar graph is a chart with rectangular bars with
                  lengths proportional to the values that they represent.
                </p>
                <div id="f2" className="ht-200 ht-sm-300" />
              </div>
              {/* card */}
            </div>
            {/* col-6 */}
            <div className="col-md-6 mg-t-15 mg-sm-t-20 mg-md-t-0">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Line Chart</h6>
                <p className="mg-b-20 mg-sm-b-30">
                  The stacked charts are used when data sets have to be broken
                  down into their constituents.
                </p>
                <div id="f1" className="ht-200 ht-sm-300" />
              </div>
              {/* card */}
            </div>
            {/* col-6 */}
          </div>
          {/* row */}
          <div className="row row-sm mg-t-15 mg-sm-t-20">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header bg-transparent pd-20">
                  <h6 className="card-title tx-uppercase tx-12 mg-b-0">
                    User Transaction History
                  </h6>
                </div>
                {/* card-header */}
                <div className="table-responsive">
                  <table className="table table-white mg-b-0 tx-12">
                    <tbody>
                      <tr>
                        <td className="pd-l-20 tx-center">
                          <img
                            src="../img/img1.jpg"
                            className="wd-36 rounded-circle"
                            alt="Image"
                          />
                        </td>
                        <td>
                          <a className="tx-inverse tx-14 tx-medium d-block">
                            Mark K. Peters
                          </a>
                          <span className="tx-11 d-block">
                            TRANSID: 1234567890
                          </span>
                        </td>
                        <td className="tx-12">
                          <span className="square-8 bg-success mg-r-5 rounded-circle" />{" "}
                          Email verified
                        </td>
                        <td>Just Now</td>
                      </tr>
                      <tr>
                        <td className="pd-l-20 tx-center">
                          <img
                            src="../img/img2.jpg"
                            className="wd-36 rounded-circle"
                            alt="Image"
                          />
                        </td>
                        <td>
                          <a className="tx-inverse tx-14 tx-medium d-block">
                            Karmen F. Brown
                          </a>
                          <span className="tx-11 d-block">
                            TRANSID: 1234567890
                          </span>
                        </td>
                        <td className="tx-12">
                          <span className="square-8 bg-warning mg-r-5 rounded-circle" />{" "}
                          Pending verification
                        </td>
                        <td>Apr 21, 2017 8:34am</td>
                      </tr>
                      <tr>
                        <td className="pd-l-20 tx-center">
                          <img
                            src="../img/img3.jpg"
                            className="wd-36 rounded-circle"
                            alt="Image"
                          />
                        </td>
                        <td>
                          <a className="tx-inverse tx-14 tx-medium d-block">
                            Gorgonio Magalpok
                          </a>
                          <span className="tx-11 d-block">
                            TRANSID: 1234567890
                          </span>
                        </td>
                        <td className="tx-12">
                          <span className="square-8 bg-success mg-r-5 rounded-circle" />{" "}
                          Purchased success
                        </td>
                        <td>Apr 10, 2017 4:40pm</td>
                      </tr>
                      <tr>
                        <td className="pd-l-20 tx-center">
                          <img
                            src="../img/img5.jpg"
                            className="wd-36 rounded-circle"
                            alt="Image"
                          />
                        </td>
                        <td>
                          <a className="tx-inverse tx-14 tx-medium d-block">
                            Ariel T. Hall
                          </a>
                          <span className="tx-11 d-block">
                            TRANSID: 1234567890
                          </span>
                        </td>
                        <td className="tx-12">
                          <span className="square-8 bg-warning mg-r-5 rounded-circle" />{" "}
                          Payment on hold
                        </td>
                        <td>Apr 02, 2017 6:45pm</td>
                      </tr>
                      <tr>
                        <td className="pd-l-20 tx-center">
                          <img
                            src="../img/img4.jpg"
                            className="wd-36 rounded-circle"
                            alt="Image"
                          />
                        </td>
                        <td>
                          <a className="tx-inverse tx-14 tx-medium d-block">
                            John L. Goulette
                          </a>
                          <span className="tx-11 d-block">
                            TRANSID: 1234567890
                          </span>
                        </td>
                        <td className="tx-12">
                          <span className="square-8 bg-pink mg-r-5 rounded-circle" />{" "}
                          Account deactivated
                        </td>
                        <td>Mar 30, 2017 10:30am</td>
                      </tr>
                      <tr>
                        <td className="pd-l-20 tx-center">
                          <img
                            src="../img/img5.jpg"
                            className="wd-36 rounded-circle"
                            alt="Image"
                          />
                        </td>
                        <td>
                          <a className="tx-inverse tx-14 tx-medium d-block">
                            John L. Goulette
                          </a>
                          <span className="tx-11 d-block">
                            TRANSID: 1234567890
                          </span>
                        </td>
                        <td className="tx-12">
                          <span className="square-8 bg-pink mg-r-5 rounded-circle" />{" "}
                          Account deactivated
                        </td>
                        <td>Mar 30, 2017 10:30am</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* table-responsive */}
                <div className="card-footer tx-12 pd-y-15 bg-transparent bd-t bd-gray-200">
                  <a>
                    <i className="fa fa-angle-down mg-r-5" />
                    View All Transaction History
                  </a>
                </div>
                {/* card-footer */}
              </div>
              {/* card */}
            </div>
            {/* col-8 */}
            <div className="col-lg-4 mg-t-15 mg-sm-t-20 mg-lg-t-0">
              <div className="card pd-20">
                <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-15">
                  Sales Report
                </h6>
                <div className="d-flex mg-b-10">
                  <div className="bd-r pd-r-10">
                    <label className="tx-12">Today</label>
                    <p className="tx-lato tx-inverse tx-bold">1,898</p>
                  </div>
                  <div className="bd-r pd-x-10">
                    <label className="tx-12">This Week</label>
                    <p className="tx-lato tx-inverse tx-bold">32,112</p>
                  </div>
                  <div className="pd-l-10">
                    <label className="tx-12">This Month</label>
                    <p className="tx-lato tx-inverse tx-bold">72,067</p>
                  </div>
                </div>
                {/* d-flex */}
                <div className="progress mg-b-10">
                  <div
                    className="progress-bar wd-50p"
                    role="progressbar"
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    50%
                  </div>
                </div>
                <p className="tx-12 mg-b-0">
                  Maecenas tempus, tellus eget condimentum rhoncus
                </p>
              </div>
              {/* card */}
              <ul className="list-group widget-list-group bg-info mg-t-20">
                <li className="list-group-item rounded-top-0">
                  <p className="mg-b-0">
                    <i className="fa fa-cube tx-white-7 mg-r-8" />
                    <strong className="tx-medium">
                      Retina: 13.3-inch
                    </strong>{" "}
                    <span className="text-muted">Display</span>
                  </p>
                </li>
                <li className="list-group-item">
                  <p className="mg-b-0">
                    <i className="fa fa-cube tx-white-7 mg-r-8" />
                    <strong className="tx-medium">
                      Intel Iris Graphics 6100
                    </strong>{" "}
                    <span className="text-muted">Graphics</span>
                  </p>
                </li>
                <li className="list-group-item">
                  <p className="mg-b-0">
                    <i className="fa fa-cube tx-white-7 mg-r-8" />
                    <strong className="tx-medium">500 GB</strong>{" "}
                    <span className="text-muted">Flash Storage</span>
                  </p>
                </li>
                <li className="list-group-item">
                  <p className="mg-b-0">
                    <i className="fa fa-cube tx-white-7 mg-r-8" />
                    <strong className="tx-medium">
                      3.1 GHz Intel Core i7
                    </strong>{" "}
                    <span className="text-muted">Processor</span>
                  </p>
                </li>
                <li className="list-group-item rounded-bottom-0">
                  <p className="mg-b-0">
                    <i className="fa fa-cube tx-white-7 mg-r-8" />
                    <strong className="tx-tx-medium">
                      16 GB 1867 MHz DDR3
                    </strong>{" "}
                    <span className="text-muted">Memory</span>
                  </p>
                </li>
              </ul>
            </div>
            {/* col-4 */}
          </div>
          {/* row */}
        </div>
        {/* am-pagebody */}
        <div className="am-footer">
          <span>
            Copyright ©. All Rights Reserved.{" "}
            <a href="http://limerickbd.com/">Limerick Resources Limited</a>
          </span>
        </div>
        {/* am-footer */}
      </div>
      ;
    </div>
  );
};

export default Admin;
