import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import Swal from "sweetalert2";
import IsLogin from "../../../Common/IsLogin";
import Select from "react-select";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Staff = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [grade, setGrade] = useState({});
  const [department, setDepartment] = useState({});
  const [designation, setDesignation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [inputs, setInputs] = useState({});

  const fetchData = () => {
    Http.get("/staff").then((res) => {
      setReceiveData(res.data);
    });
    // Http.get("/grade").then((res) => {
    //   setGrade(res.data);
    // });

    Http.get("/gradeSelect2").then((res) => {
      setGrade(res.data);
    });

    // Http.get("/department").then((res) => {
    //   setDepartment(res.data);
    // });

    Http.get("/department2").then((res) => {
      setDepartment(res.data);
    });

    // Http.get("/designation").then((res) => {
    //   setDesignation(res.data);
    // });

    Http.get("/designationSelect2").then((res) => {
      setDesignation(res.data);
    });

    // Http.get("/location").then((res) => {
    //   setCompanyLocation(res.data);
    // });

    Http.get("/locationSelect2").then((res) => {
      setCompanyLocation(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  //  Set Input Value with state
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Form Submit
  const formSubmit = () => {
    Http.post("/staff-filter/", inputs).then((res) => {
      setReceiveData(res.data);
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Staff</h5>
            <Link to="/create-staff" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Staff
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="col-lg">
                  <label>Department</label>
                  <Select className="basic-single" classNamePrefix="select" defaultValue={department[0]} name="department_id" options={department} onChange={selectHandleChange} />
                </div>
                <div className="col-lg">
                  <label>Grade</label>
                  <Select className="basic-single" classNamePrefix="select" defaultValue={grade[0]} name="grade_id" options={grade} onChange={selectHandleChange} />
                </div>
                <div className="col-lg">
                  <label>Designation</label>
                  <Select className="basic-single" classNamePrefix="select" defaultValue={designation[0]} name="designation_id" options={designation} onChange={selectHandleChange} />
                </div>
                <div className="col-lg">
                  <label>Company Location</label>
                  <Select className="basic-single" classNamePrefix="select" defaultValue={companyLocation[0]} name="company_location_id" options={companyLocation} onChange={selectHandleChange} />
                </div>
                <div className="col-lg">
                  <label>Type</label>
                  <select className="form-control" name="type" onChange={handleChange}>
                    <option value="">Select Type</option>
                    <option value="permanent">Permanent</option>
                    <option value="contractual">Contractual</option>
                    <option value="internship">Internship</option>
                  </select>
                </div>
                <div className="col-lg">
                  <label>Status</label>
                  <select className="form-control" name="status" onChange={handleChange}>
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="col-lg">
                  <label>.</label>
                  <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Card No</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Name</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Email</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Mobile</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Designation</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Department</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Location</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Type</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Joining Date</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Blood Group</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Date Of Birth</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Type</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Status</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Gross Salary</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2" style={{ width: "100px !important" }}>
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{item.card_no}</td>
                          <td className="text-xs">
                            <Link to={{ pathname: "/profile/" + item.id }}>{item.name}</Link>
                          </td>
                          <td className="text-xs">{item.email}</td>
                          <td className="text-xs">{item.mobile}</td>
                          <td className="text-xs">{item.designation ? item.designation.name : ""}</td>
                          <td className="text-xs">{item.department ? item.department.name : ""}</td>
                          <td className="text-xs">{item.company_location ? item.company_location.name : ""}</td>
                          <td className="text-xs">{item.type}</td>
                          <td className="text-xs">{item.joining_date}</td>
                          <td className="text-xs">{item.blood_group}</td>
                          <td className="text-xs">{item.date_of_birth}</td>
                          <td className="text-xs">{item.type}</td>
                          <td className="text-xs">{item.status}</td>
                          <td className="text-xs" align="right">
                            {item.salary_structure_latest != null ? parseFloat(item.salary_structure_latest.gross).toFixed(2) : 0}
                          </td>
                          <td className="text-xs">
                            <Link to={{ pathname: "/edit-staff/" + item.id }} className="btn btn-primary btn-sm">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                            <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "staff", "Staff")}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
