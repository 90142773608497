import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import FormValidation from "../../../Common/FormValidation";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const { id } = useParams();
  const { checkRequiredFields } = useRequiredFields();

  const fetchData = () => {
    Http.get("/editGrade/" + id).then((res) => {
      setInputs({
        name: res.data.name,
        grade_code: res.data.grade_code,
        increment_rate: res.data.increment_rate,
        basic_salary: res.data.basic_salary,
        house_rent: res.data.house_rent,
        medical_allowance: res.data.medical_allowance,
        conveyance_allowance: res.data.conveyance_allowance,
        entertainment_allowance: res.data.entertainment_allowance,
        tada_allowance: res.data.tada_allowance,
        sale_center_allowance: res.data.sale_center_allowance,
        holiday_rate: res.data.holiday_rate,
        insurance_rate: res.data.insurance_rate,
        income_tax: res.data.income_tax,
        gross_salary: res.data.gross_salary,
        phone_bill_limit: res.data.phone_bill_limit,
        canteen_bill: res.data.canteen_bill,
        circular_no: res.data.circular_no,
        circular_date: res.data.circular_date,
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.post("/updateGrade/" + id, inputs)
        .then((res) => {
          navigate("/grade", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };
  return (
    <div>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Grade</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Grade Form</h6>

            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row mb-3">
              {/* Name */}
              <div className="col-lg">
                <label className="font-weight-bold" htmlFor="">
                  Name <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  placeholder="Input Name"
                  data-required
                />
              </div>
              {/* Grade Code */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="grade_code">
                  Grade Code <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="grade_code"
                  value={inputs.grade_code || ""}
                  onChange={handleChange}
                  placeholder="Input Grade Code"
                  data-required
                />
              </div>
              {/* Increment Rate */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="increment_rate">
                  Increment Rate <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="increment_rate"
                  value={inputs.increment_rate || ""}
                  onChange={handleChange}
                  placeholder="Input Increment Rate"
                  data-required
                />
              </div>
            </div>

            <div className="row mb-3">
              {/* Basic Salary */}
              <div className="col-lg">
                <label className="font-weight-bold" htmlFor="basic_salary">
                  Basic Salary <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="basic_salary"
                  value={inputs.basic_salary || ""}
                  onChange={handleChange}
                  placeholder="Input Basic Salary"
                  data-required
                />
              </div>
              {/* House Rent */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="house_rent">
                  House Rent <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="house_rent"
                  value={inputs.house_rent || ""}
                  onChange={handleChange}
                  placeholder="Input House Rent"
                  data-required
                />
              </div>
              {/* Medical Allowance */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="medical_allowance">
                  Medical Allowance <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="medical_allowance"
                  value={inputs.medical_allowance || ""}
                  onChange={handleChange}
                  placeholder="Input Medical Allowance"
                  data-required
                />
              </div>
            </div>

            <div className="row mb-3">
              {/* Conveyence Allowence */}
              <div className="col-lg">
                <label
                  className="font-weight-bold"
                  htmlFor="conveyance_allowance"
                >
                  Conveyence Allowence <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="conveyance_allowance"
                  value={inputs.conveyance_allowance || ""}
                  onChange={handleChange}
                  placeholder="Input Conveyence Allowence"
                  data-required
                />
              </div>
              {/* Entertainment Allowence */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label
                  className="font-weight-bold"
                  htmlFor="entertainment_allowance"
                >
                  Entertainment Allowence{" "}
                  <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="entertainment_allowance"
                  value={inputs.entertainment_allowance || ""}
                  onChange={handleChange}
                  placeholder="Input Entertainment Allowence"
                  data-required
                />
              </div>
              {/* TA / DA */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="tada_allowance">
                  TA / DA <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="tada_allowance"
                  value={inputs.tada_allowance || ""}
                  onChange={handleChange}
                  placeholder="Input TA / DA"
                  data-required
                />
              </div>
            </div>

            <div className="row mb-3">
              {/* Sale Center Allowence */}
              <div className="col-lg">
                <label
                  className="font-weight-bold"
                  htmlFor="sale_center_allowance"
                >
                  Sale Center Allowence <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="sale_center_allowance"
                  value={inputs.sale_center_allowance || ""}
                  onChange={handleChange}
                  placeholder="Sale Center Allowence"
                  data-required
                />
              </div>
              {/* Holiday Rate */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="holiday_rate">
                  Holiday Rate <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="holiday_rate"
                  value={inputs.holiday_rate || ""}
                  onChange={handleChange}
                  placeholder="Input Holiday Rate"
                  data-required
                />
              </div>
              {/* Insurance Rate */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="insurance_rate">
                  Insurance Rate <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="insurance_rate"
                  value={inputs.insurance_rate || ""}
                  onChange={handleChange}
                  placeholder="Input Insurance Rate"
                  data-required
                />
              </div>
            </div>

            <div className="row mb-3">
              {/* Income Tax */}
              <div className="col-lg">
                <label className="font-weight-bold" htmlFor="income_tax">
                  Income Tax <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="income_tax"
                  value={inputs.income_tax || ""}
                  onChange={handleChange}
                  placeholder="Input Income Tax"
                  data-required
                />
              </div>
              {/* Gross Salary */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="gross_salary">
                  Gross Salary <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="gross_salary"
                  value={inputs.gross_salary || ""}
                  onChange={handleChange}
                  placeholder="Input Gross Salary"
                  data-required
                />
              </div>
              {/* Phone Bill Limit */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="phone_bill_limit">
                  Phone Bill Limit <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="phone_bill_limit"
                  value={inputs.phone_bill_limit || ""}
                  onChange={handleChange}
                  placeholder="Input Phone Bill Limit"
                  data-required
                />
              </div>
            </div>

            <div className="row mb-3">
              {/* Canteen */}
              <div className="col-lg">
                <label className="font-weight-bold" htmlFor="canteen_bill">
                  Canteen <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="canteen_bill"
                  value={inputs.canteen_bill || ""}
                  onChange={handleChange}
                  placeholder="Input Canteen"
                  data-required
                />
              </div>
              {/* Circular No */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="circular_no">
                  Circular No
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="circular_no"
                  value={inputs.circular_no || ""}
                  onChange={handleChange}
                  placeholder="Input Circular No"
                />
              </div>
              {/* Circular Date */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label className="font-weight-bold" htmlFor="circular_date">
                  Circular Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="circular_date"
                  value={inputs.circular_date || ""}
                  onChange={handleChange}
                  placeholder="Input Circular Date"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
