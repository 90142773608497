import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../../Common/Http";
import FormValidation from "../../../../Common/FormValidation";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [department, setDepartment] = useState({});
  const [asset, setAsset] = useState({});
  const [unit, setUnit] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState({});
  const [vendor, setVendor] = useState({});
  const [error, setError] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [url, setUrl] = useState(Http.getUri());

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDiscountChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    const inputInfo = [...list];
    amountAndFinalAmount(inputInfo);
  };
  const handleFileChange = (e) => {
    const fileArray = [];
    const files = e.target.files;
    Object.keys(files).forEach((key) => {
      fileArray.push(e.target.files[key]);
    });
    setSelectedFile(fileArray);
    setIsSelected(true);
  };
  // Set Experience State
  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable
    let price =
      parseFloat(inputInfo[index]["price"]) *
      (parseFloat(inputInfo[index]["qty"]) *
        parseFloat(inputInfo[index]["unit_qty"]));
    let vat = (parseFloat(price) * parseFloat(inputInfo[index]["vat"])) / 100;
    let tax = (parseFloat(price) * parseFloat(inputInfo[index]["tax"])) / 100;

    inputInfo[index]["amount"] = parseFloat(price);
    inputInfo[index]["final_amount"] =
      parseFloat(price) + parseFloat(vat) + parseFloat(tax);
    inputInfo[index][name] = value;
    setList(inputInfo);

    amountAndFinalAmount(inputInfo);
  };
  const amountAndFinalAmount = (inputInfo) => {
    let amount = 0;
    Object.keys(inputInfo).forEach((key) => {
      amount += inputInfo[key].amount > 0 ? inputInfo[key].amount : 0;
    });
    setTotalAmount(amount);

    let finalAmount = 0;
    Object.keys(inputInfo).forEach((key) => {
      finalAmount +=
        inputInfo[key].final_amount > 0 ? inputInfo[key].final_amount : 0;
    });
    setFinalAmount(finalAmount - inputs.discount);
  };
  const fetchData = () => {
    Http.get("/vendor").then((res) => {
      setVendor(res.data);
    });
    Http.get("/location").then((res) => {
      setLocation(res.data);
    });
    Http.get("/factory").then((res) => {
      setFactory(res.data);
    });
    Http.get("/department").then((res) => {
      setDepartment(res.data);
    });
    Http.get("/asset").then((res) => {
      setAsset(res.data);
    });
    Http.get("/unit").then((res) => {
      setUnit(res.data);
    });
    Http.get("/asset-circular-rfq/" + id + "/edit").then((res) => {
      setInputs({
        asset_circular_rfq_id: id,
        date: res.data.date,
        title: res.data.title,
        subject: res.data.subject,
        description: res.data.description,
        circular_no: res.data.circular_no,
        last_date_of_quotation: res.data.last_date_of_quotation,
        quotation_validaty: res.data.quotation_validaty,
        company_location_id: res.data.company_location_id,
        factory_id: res.data.factory_id,
        department_id: res.data.department_id,
        status: res.data.status,
        discount: 0,
      });

      const listInfo = [];
      Object.keys(res.data.items).forEach((key) => {
        listInfo.push({
          asset_id: res.data.items[key].asset_id,
          unit_id: res.data.items[key].unit_id,
          unit_qty: res.data.items[key].unit.qty,
          qty: res.data.items[key].qty,
          vat: 0,
          tax: 0,
          desc: res.data.items[key].desc,
        });
      });
      setList(listInfo);
    });
    Http.get("/unit").then((res) => {
      setUnit(res.data);
    });
  };

  useEffect(() => {
    fetchData();

    const splitUrl = url.split("/");
    let fullUrl = "";
    for (var i = 0; i < splitUrl.length - 1; i++) {
      fullUrl += splitUrl[i] + "/";
    }
    setUrl(fullUrl);
  }, []);

  const formSubmit = () => {
    const listArr = [];
    Object.keys(list).forEach((key) => {
      listArr.push(list[key]);
    });

    inputs.file = selectedFile;
    inputs.items = listArr;
    inputs.total_amount = finalAmount;

    // console.log(inputs);
    Http.post("/asset-rfq-sent-from-vendor", inputs)
      .then((res) => {
        navigate("/asset-circular-rfq", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Vendor Asset Quotation Form</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Asset Quotation Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>

            <div className="row">
              <div className="col-lg">
                <label>Vendor</label>
                <select
                  className="form-control"
                  name="vendor_id"
                  onChange={handleChange}
                >
                  <option value="">Select Vendor</option>
                  {Array.from(vendor).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>Quotation Validaty Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="quotation_validaty_date"
                  value={inputs.quotation_validaty_date || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks || ""}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>

            <div className="row mt-3">
              {/* <div className="col-10"></div>
              <div className="col-2">
                <button className="btn btn-info btn-block mg-b-10" onClick={addMoreList}>
                  <i className="fa fa-plus mg-r-10"></i> ADD More
                </button>
              </div> */}
              <table className="table table-sm align-items-center mb-0">
                <thead>
                  <tr>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      asset
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Unit
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Qty
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Price
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Vat
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Tax
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Description
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Amount
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Final Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(list).map((x, i) => (
                    <tr>
                      <td>
                        <select
                          className="form-control"
                          name="asset_id"
                          onChange={(e) => handleInputChange(e, i)}
                          disabled
                        >
                          <option value="">Select asset</option>
                          {Array.from(asset).map((item, index) => (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={x.asset_id === item.id ? true : false}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-control"
                          name="unit_id"
                          onChange={(e) => handleInputChange(e, i)}
                          disabled
                        >
                          <option value="">Select Unit</option>
                          {Array.from(unit).map((item, index) => (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={x.unit_id === item.id ? true : false}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="qty"
                          value={x.qty || ""}
                          onChange={(e) => handleInputChange(e, i)}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="price"
                          value={x.price || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="vat"
                          value={x.vat || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="tax"
                          value={x.tax || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="desc"
                          value={x.desc || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        ></textarea>
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="amount"
                          value={x.amount || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="final_amount"
                          value={x.final_amount || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={8} style={{ textAlign: "right" }}>
                      <label style={{ fontWeight: "bold", lineHeight: "35px" }}>
                        Discount
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="discount"
                        value={inputs.discount || ""}
                        onChange={handleDiscountChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={7} style={{ textAlign: "right" }}>
                      Total :{" "}
                    </td>
                    <td style={{ textAlign: "right" }}>{totalAmount}</td>
                    <td style={{ textAlign: "right" }}>{finalAmount}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>Attachment</label>
                <input
                  type="file"
                  name="attachment"
                  className="form-control"
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
