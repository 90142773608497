import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import IsLogin from "../../Common/IsLogin";
import { LoaderComponent } from "../../Common/LoaderComponent";

const Reports = () => {
  const [active, setActive] = useState(window.location.pathname);
  const location = useLocation();
  if (location.pathname === "/" || location.pathname === "/login") return null;

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Reports</h5>
        </div>
        {/* am-pagetitle */}
        <div className="am-pagebody">
          <div className="row row-sm mg-t-15 mg-sm-t-20">
            <div className="col-md-6">
              <div className="card bd-0">
                <div className="card-header card-header-default bg-primary">
                  Hr Managment Report
                </div>
                <div className="card-body bd bd-t-0 rounded-bottom">
                  <div className="report_style">
                    <div className="report-item">
                      <Link to="/hr-salary-structure">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Salary Structure</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/hr-leave-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Leave Report</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/monthly-salary-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Monthly Salary Report</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/attendance-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Attendance Report</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/salary-payment-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Paid Salary Report</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/apa-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>APA Report</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* card */}
            </div>
            {/* col-6 */}
            {/* <div className="col-md-6">
              <div className="card bd-0">
                <div className="card-header card-header-default bg-primary">
                  Accounts
                </div>
                <div className="card-body bd bd-t-0 rounded-bottom">
                  <div className="report_style">
                    <div className="report-item">
                      <Link to="/chart-of-account-ledger-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Chart Of Account Ledger Report</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/sub-category-list">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Subcategory Ledger Report</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/vendor-supplier-payable-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Vendor/Supplier Payable</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/distributor-payable-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Distributor Payable</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/receipt-payment-summary-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Receipts & Payments Summary</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/vendor-supplier-receivable-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Vendor/Supplier Receivable</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/distributor-receivable-report">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Distributor Receivable</span>
                      </Link>
                    </div>
                    <div className="report-item">
                      <Link to="/trial-balance">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <span>Trial Balance</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* row */}
        </div>
        {/* am-pagebody */}
        <div className="am-footer">
          <span>
            Copyright ©. All Rights Reserved.{" "}
            <a href="http://limerickbd.com/">Limerick Resources Limited</a>
          </span>
        </div>
        {/* am-footer */}
      </div>
      {/* am-mainpanel */}
    </div>
  );
};

export default Reports;
