import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sales = (props) => {
  const [active, setActive] = useState(window.location.pathname);
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "sales" ? "active show-sub" : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Sales</span>
        </Link>
        <ul className="nav-sub">
          <li className="nav-item">
            <Link
              to="/distributors"
              className={`nav-link ${
                active === "distributors" ? "active" : ""
              }`}
              onClick={() => setActive("distributors")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Distributors
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/primary-sales"
              className={`nav-link ${
                active === "primary-sales" ? "active" : ""
              }`}
              onClick={() => setActive("primary-sales")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Primary Sales
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default Sales;
