import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Http from "../Common/Http";
import BasicSetup from "./Sidebar/BasicSetup";
import Workplace from "./Sidebar/Workplace";
import StaffManagement from "./Sidebar/StaffManagement";
import Payroll from "./Sidebar/Payroll";
import Accounts from "./Sidebar/Accounts";
import SupplyChain from "./Sidebar/SupplyChain";
import Production from "./Sidebar/Production";
import Assets from "./Sidebar/Assets";
import Sales from "./Sidebar/Sales";
import SecondarySales from "./Sidebar/SecondarySales";
import $ from "jquery";

const Sidebar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const [receiveData, setReceiveData] = useState([]);
  const location = useLocation();

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Initialize the Bootstrap collapse plugin for the newly added elements
    $(".show-sub + .nav-sub").slideDown();

    $(".with-sub").on("click", function (e) {
      e.preventDefault();
      $(this).next().slideToggle();
      $(this).toggleClass("show-sub");
    });
  }, [receiveData]);

  if (location.pathname === "/" || location.pathname === "/login") return null;

  return (
    <div>
      <div className="am-sideleft">
        <ul className="nav am-sideleft-tab">
          <li className="nav-item">
            <a href="#mainMenu" className="nav-link active">
              <i className="icon ion-ios-home-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#emailMenu" className="nav-link">
              <i className="icon ion-ios-email-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#chatMenu" className="nav-link">
              <i className="icon ion-ios-chatboxes-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#settingMenu" className="nav-link">
              <i className="icon ion-ios-gear-outline tx-24" />
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div id="mainMenu" className="tab-pane active">
            {/* {sidebarComponent} */}
            <ul className="nav am-sideleft-menu">
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={`nav-link ${
                    active === "/dashboard" ? "active" : ""
                  }`}
                  onClick={() => setActive("/dashboard")}
                >
                  <i className="fa fa-tachometer" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              {/* Basic Setup */}
              {(receiveData === "admin" || receiveData === "hr") && (
                <BasicSetup />
              )}
              {/* WorkPlace */}
              {(receiveData === "admin" || receiveData === "hr") && (
                <Workplace />
              )}
              {(receiveData === "admin" ||
                receiveData === "hr" ||
                receiveData === "staff") && <StaffManagement />}

              {/* Staff Management */}
              {/* <StaffManagement receiveData={receiveData} /> */}
              {/* <li className="nav-item">
                <Link
                  to
                  className={`nav-link with-sub ${
                    active === "staff" ||
                    active === "requisition" ||
                    active === "candidate" ||
                    active === "leave-deduction" ||
                    active === "leave-aplication" ||
                    active === "bill-submission" ||
                    active === "attendance" ||
                    active === "movement-slip"
                      ? "active show-sub"
                      : ""
                  }`}
                >
                  <i className="fa fa-university" aria-hidden="true"></i>
                  <span>Staff Management</span>
                </Link>
                <ul className="nav-sub">
                  {(receiveData === "admin" || receiveData === "hr") && (
                    <li className="nav-item">
                      <Link
                        to="/staff"
                        className={`nav-link ${
                          active === "/staff" ? "active" : ""
                        }`}
                        onClick={() => setActive("/staff")}
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                        &nbsp;&nbsp;
                        <span>Staff</span>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/requisition"
                      className={`nav-link ${
                        active === "/requisition" ? "active" : ""
                      }`}
                      onClick={() => setActive("/requisition")}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                      &nbsp;&nbsp;
                      <span>Requisition</span>
                    </Link>
                  </li>

                  {(receiveData === "admin" || receiveData === "hr") && (
                    <>
                      <li className="nav-item">
                        <Link
                          to="/candidate"
                          className={`nav-link ${
                            active === "/candidate" ? "active" : ""
                          }`}
                          onClick={() => setActive("/candidate")}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                          &nbsp;&nbsp;
                          <span>Candidate</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/leave-deduction"
                          className={`nav-link ${
                            active === "/leave-deduction" ? "active" : ""
                          }`}
                          onClick={() => setActive("/leave-deduction")}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                          &nbsp;&nbsp;
                          <span>Leave Deduction</span>
                        </Link>
                      </li>
                    </>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/leave-application"
                      className={`nav-link ${
                        active === "/leave-application" ? "active" : ""
                      }`}
                      onClick={() => setActive("/leave-application")}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                      &nbsp;&nbsp;
                      <span>Leave Application</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/billsubmission"
                      className={`nav-link ${
                        active === "billsubmission" ? "active" : ""
                      }`}
                      onClick={() => setActive("billsubmission")}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                      &nbsp;&nbsp; Bill Submission
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/movement-slip"
                      className={`nav-link ${
                        active === "movement-slip" ? "active" : ""
                      }`}
                      onClick={() => setActive("movement-slip")}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                      &nbsp;&nbsp; Movement Slip
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/attendance"
                      className={`nav-link ${
                        active === "attendance" ? "active" : ""
                      }`}
                      onClick={() => setActive("attendance")}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                      &nbsp;&nbsp; Attendance
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* Payroll */}
              {(receiveData === "admin" || receiveData === "hr") && <Payroll />}

              {/* Accounts */}
              {(receiveData === "admin" || receiveData === "accounts") && (
                <Accounts />
              )}

              {/* Supply Chain */}
              {(receiveData === "admin" || receiveData === "supply_chain") && (
                <SupplyChain />
              )}

              {/* Production */}
              {/* <li className="nav-item">
                <Link
                  to="/receipe-factory"
                  className={`nav-link ${
                    active === "receipe-factory" ? "active" : ""
                  }`}
                  onClick={() => setActive("receipe-factory")}
                >
                  <i className="fa fa-clone" aria-hidden="true"></i>
                  &nbsp;&nbsp; Receipe Factory
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/depo-transfer"
                  className={`nav-link ${
                    active === "depo-transfer" ? "active" : ""
                  }`}
                  onClick={() => setActive("depo-transfer")}
                >
                  <i className="fa fa-clone" aria-hidden="true"></i>
                  &nbsp;&nbsp; Depo Transfer
                </Link>
              </li> */}
              {(receiveData === "admin" || receiveData === "production") && (
                <Production />
              )}

              {/* Assets */}
              {(receiveData === "admin" || receiveData === "supply_chain") && (
                <Assets />
              )}

              {/* Sales */}
              {(receiveData === "admin" || receiveData === "depot") && (
                <Sales />
              )}

              {/* Secondary Sales */}
              {(receiveData === "admin" || receiveData === "depot") && (
                <SecondarySales />
              )}

              {/* Reports */}
              {(receiveData === "admin" || receiveData === "reports") && (
                <li className="nav-item">
                  <Link
                    to="/reports"
                    className={`nav-link ${
                      active === "/reports" ? "active" : ""
                    }`}
                    onClick={() => setActive("/reports")}
                  >
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <span>Reports</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {/* #mainMenu */}
        </div>
        {/* tab-content */}
      </div>
      {/* am-sideleft */}
    </div>
  );
};

export default Sidebar;
