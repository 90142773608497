import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import FormValidation from "../../../Common/FormValidation";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [base, setBase] = useState({});
  const [rsm, setRsm] = useState({});
  const [factory, setFactory] = useState({});
  // if upload has any File
  const [selectedFile1, setSelectedFile1] = useState();
  const [selectedFile2, setSelectedFile2] = useState();
  const [selectedFile3, setSelectedFile3] = useState();
  const [selectedFile4, setSelectedFile4] = useState();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSelect2Change = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const fetchData = () => {
    let data = {};
    data.user_type = "rsm";
    Http.post("/staff-filter", data).then((res) => {
      setRsm(res.data);
    });
    Http.get("/base2").then((res) => {
      setBase(res.data);
    });
    Http.get("/factory").then((res) => {
      setFactory(res.data);
    });

    Http.get("/last-code-increment-by-one/distributors/code/D").then((res) => {
      setInputs({
        code: res.data,
      });
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // if upload has any File
  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };
  const handleFileChange2 = (e) => {
    setSelectedFile2(e.target.files[0]);
  };
  const handleFileChange3 = (e) => {
    setSelectedFile3(e.target.files[0]);
  };
  const handleFileChange4 = (e) => {
    setSelectedFile4(e.target.files[0]);
  };

  const formSubmit = () => {
    Http.post("/distributor", inputs)
      .then((res) => {
        navigate("/distributors", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Distributor</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Create Distributor Form
            </h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>
                  Code <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={inputs.code || ""}
                  onChange={handleChange}
                  placeholder="Input code"
                />
              </div>
              <div className="col-lg">
                <label>
                  Distributor Name <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  placeholder="Input Name"
                />
              </div>
              <div className="col-lg">
                <label>
                  Contact Person <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="contact_person"
                  value={inputs.contact_person || ""}
                  onChange={handleChange}
                  placeholder="Input Contact Person"
                />
              </div>

              <div className="col-lg">
                <label>Contact Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="contact_phone"
                  value={inputs.contact_phone || ""}
                  onChange={handleChange}
                  placeholder="Input Contact Phone"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>Secondary Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="secondary_phone"
                  value={inputs.secondary_phone || ""}
                  onChange={handleChange}
                  placeholder="Input Secondary Phone"
                />
              </div>
              <div className="col-lg">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  placeholder="Input email"
                />
              </div>
              <div className="col-lg">
                <label>Opening Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="opening_date"
                  value={inputs.opening_date || ""}
                  onChange={handleChange}
                  placeholder="Input opening_date"
                />
              </div>
              <div className="col-lg">
                <label>Previous Due</label>
                <input
                  type="number"
                  className="form-control"
                  name="previous_due"
                  value={inputs.previous_due || ""}
                  onChange={handleChange}
                  placeholder="Input Previous Due"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>Present Address</label>
                <textarea
                  className="form-control"
                  name="present_address"
                  onChange={handleChange}
                >
                  {inputs.present_address || ""}
                </textarea>
              </div>
              <div className="col-lg">
                <label>Permanent Address</label>
                <textarea
                  className="form-control"
                  name="permanent_address"
                  onChange={handleChange}
                >
                  {inputs.permanent_address || ""}
                </textarea>
              </div>
              <div className="col-lg">
                <label>Godown Address</label>
                <textarea
                  className="form-control"
                  name="godown_address"
                  onChange={handleChange}
                >
                  {inputs.godown_address || ""}
                </textarea>
              </div>
              <div className="col-lg">
                <label>
                  Business Type <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="business_type"
                  required=""
                  onChange={handleChange}
                >
                  <option value="">Select Type</option>
                  <option value="retail">Retail</option>
                  <option value="wholesale">Wholesale</option>
                  <option value="commission">Commission</option>
                  <option value="institute">Institute</option>
                  <option value="temporary">Temporary</option>
                  <option value="rdso">RDSO</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  Distributor Base <i className="fa fa-star required"></i>
                </label>
                {/* <select
                  className="form-control"
                  name="base_id"
                  required=""
                  onChange={handleChange}
                >
                  <option value="">Select base</option>
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={base[0]}
                  name="base_id"
                  options={base}
                  onChange={handleSelect2Change}
                />
              </div>
              {/* <div className="col-lg">
                <label>Region</label>
                <select
                  className="form-control"
                  name="business_type"
                  disabled="disabled"
                  onChange={handleChange}
                >
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>Area</label>
                <select
                  className="form-control"
                  name="business_type"
                  disabled="disabled"
                  onChange={handleChange}
                >
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-lg">
                <label>Distributor Opened By</label>
                <select
                  className="form-control"
                  name="opened_by"
                  onChange={handleChange}
                >
                  <option value="">Select RSM</option>
                  {Array.from(rsm).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  SR Base <i className="fa fa-star required"></i>
                </label>
                {/* <select
                  className="form-control"
                  name="sr_base"
                  onChange={handleChange}
                >
                  <option value="">Select SR Base</option>
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={base[0]}
                  name="sr_base"
                  options={base}
                  onChange={handleSelect2Change}
                />
              </div>
              <div className="col-lg">
                <label>Assign To a Factory</label>
                <select
                  className="form-control"
                  name="factory_id"
                  onChange={handleChange}
                >
                  <option value="">Select Factory</option>
                  {Array.from(factory).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* NID */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>NID No</label>
                <input
                  type="text"
                  className="form-control"
                  name="nid"
                  value={inputs.nid || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg">
                <label>NID Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="nid_img"
                  onChange={handleFileChange4}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* license */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>License No</label>
                <input
                  type="text"
                  className="form-control"
                  name="license_no"
                  value={inputs.license_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="license_no_validaty_date"
                  value={inputs.license_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>License Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="license_img"
                  onChange={handleFileChange1}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* Vat */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>Vat Reg. No</label>
                <input
                  type="text"
                  className="form-control"
                  name="vat_reg_no"
                  value={inputs.vat_reg_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="vat_reg_no_validaty_date"
                  value={inputs.vat_reg_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>Vat Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="vat_reg_img"
                  onChange={handleFileChange2}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* TIN */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>TIN No</label>
                <input
                  type="text"
                  className="form-control"
                  name="tin_no"
                  value={inputs.tin_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="tin_no_validaty_date"
                  value={inputs.tin_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>Vat Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="tin_img"
                  onChange={handleFileChange3}
                  placeholder="Input license no"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
