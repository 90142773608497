import React, { useEffect, useState } from "react";
import { Loader } from "./Loader";

const LoaderComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loaderData = async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulating a 2-second delay
      setIsLoading(false);
    };

    loaderData();
  }, []);

  return (
    <div>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default LoaderComponent;
export { LoaderComponent };
