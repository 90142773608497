import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import FormValidation from "../../../Common/FormValidation";
import Select from "react-select";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [unit, setUnit] = useState({});
  const [category, setCategory] = useState({});
  const [error, setError] = useState({});

  const [selectedUnitOption, setSelectedUnitOption] = useState(null);
  const [selectProductCatOption, setSelectProductCatOption] = useState(null);

  const fetchData = () => {
    Http.get("/product/" + id + "/edit").then((res) => {
      setInputs({
        code: res.data.code,
        name: res.data.name,
        unit_id: res.data.unit_id,
        price: res.data.price,
        sku: res.data.sku,
        pack_size: res.data.pack_size,
        stock: res.data.stock,
        type: res.data.type,
        category_id: res.data.category_id,
        notes: res.data.notes,
        vat: res.data.vat,
        status: res.data.status,
      });

      setSelectedUnitOption({
        label: res.data.unit.name,
        value: res.data.unit.id,
      });

      setSelectProductCatOption({
        label: res.data.category.name,
        value: res.data.category.id,
      });
    });

    // Http.get("/unit").then((res) => {
    //   setUnit(res.data);
    // });
    // Http.get("/product-category").then((res) => {
    //   setCategory(res.data);
    // });

    Http.get("/unitSelect2").then((res) => {
      setUnit(res.data);
    });

    Http.get("/productCatSelect2").then((res) => {
      setCategory(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selectHandleChange = (selectedOption, actionMeta) => {
    if (actionMeta.name === "unit_id") {
      setSelectedUnitOption({
        label: selectedOption.label,
        value: selectedOption.value,
      });
    }

    if (actionMeta.name === "category_id") {
      setSelectProductCatOption({
        label: selectedOption.label,
        value: selectedOption.value,
      });
    }

    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    Http.put("/product/" + id, inputs)
      .then((res) => {
        navigate("/product", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Product Form</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Product Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>

            <div className="row">
              <div className="col-lg">
                <label>
                  Code <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={inputs.code || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>
                  Name <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Unit <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectedUnitOption}
                  value={selectedUnitOption}
                  onChange={selectHandleChange}
                  name="unit_id"
                  options={unit}
                />

                {/* <select
                  className="form-control"
                  name="unit_id"
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  {Array.from(unit).map((item, index) => (
                    <option
                      value={item.id}
                      key={item.id}
                      selected={inputs.unit_id === item.id ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="col-lg">
                <label>
                  Price <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={inputs.price || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  SKU <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="sku"
                  value={inputs.sku || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>Pack Size</label>
                <input
                  type="text"
                  className="form-control"
                  name="pack_size"
                  value={inputs.pack_size || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>Stock</label>
                <input
                  type="text"
                  className="form-control"
                  name="stock"
                  value={inputs.stock || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>
                  Type <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="type"
                  onChange={handleChange}
                >
                  <option value="">Select Type</option>
                  <option
                    value="raw_materials"
                    selected={inputs.type === "raw_materials" ? true : false}
                  >
                    Raw Materials
                  </option>
                  <option
                    value="packing_materials"
                    selected={
                      inputs.type === "packing_materials" ? true : false
                    }
                  >
                    Packing Materials
                  </option>
                  <option
                    value="finished_goods"
                    selected={inputs.type === "finished_goods" ? true : false}
                  >
                    Finished Goods
                  </option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">
                  Category <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectProductCatOption}
                  value={selectProductCatOption}
                  onChange={selectHandleChange}
                  name="category_id"
                  options={category}
                />

                {/* <select
                  className="form-control"
                  name="category_id"
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {Array.from(category).map((item, index) => (
                    <option
                      value={item.id}
                      key={item.id}
                      selected={inputs.category_id === item.id ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="col-lg">
                <label>Vat</label>
                <input
                  type="text"
                  className="form-control"
                  name="vat"
                  value={inputs.vat || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>Notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  value={inputs.notes || ""}
                  onChange={handleChange}
                  placeholder="Notes"
                />
              </div>
              <div className="col-lg">
                <label>Status</label>
                <select
                  className="form-control"
                  name="status"
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option
                    value="active"
                    selected={inputs.status === "active" ? true : false}
                  >
                    Active
                  </option>
                  <option
                    value="inactive"
                    selected={inputs.status === "inactive" ? true : false}
                  >
                    Inactive
                  </option>
                </select>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
